import Grid from '@mui/material/Grid';
import { PropsFor } from '@mui/system';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React, { ComponentProps, Dispatch, SetStateAction } from 'react';
import { StSwitch } from 'suites/sterling/molecules/inputs/switch/Switch';
import { enUS, frFR } from '@mui/x-data-grid/locales'
import { useLanguage } from 'components/util/Language';

interface ToolbarProps {
  title?: string;
  tableTitle?: React.ReactNode;
  enableShowChange?: boolean;
  disableExport?: boolean;
}
interface ShowChangeProps {
  showChange?: boolean;
  setShowChange?: Dispatch<SetStateAction<boolean>>;
}

function ToolbarButtons({
  title,
  disableExport,
  ...rest
}: ToolbarProps & PropsFor<typeof Grid>) {
  return (
    <Grid item {...rest}>
      <GridToolbarFilterButton slotProps={{ button: { style: { marginRight: disableExport ? 0 : 10 } } }} />
      {!disableExport && <GridToolbarExport csvOptions={{ fileName: title }} slotProps={{ button: { style: { lineHeight: 1.7 } } }} />}
    </Grid>
  );
}
function ShowChangeSwitch({ showChange = false, setShowChange }: ShowChangeProps) {
  // eslint-disable-next-line
  const showChangeCallback = setShowChange || console.log;
  return (
    <Grid item>
      <StSwitch
        enabled={showChange}
        toggle={() => showChangeCallback(!showChange)}
        label="Show Change"
      />
    </Grid>
  );
}
function Toolbar({
  showChange,
  setShowChange,
  title,
  enableShowChange,
  tableTitle,
  disableExport,
}: ToolbarProps & ShowChangeProps) {
  return (
    <GridToolbarContainer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="wrap"
        sx={{ padding: (theme) => theme.spacing(1) }}
      >
        {enableShowChange ? (
          <Grid container justifyContent="space-between">
            <ToolbarButtons {...{ title, disableExport }} xs={6} />
            <ShowChangeSwitch {...{ showChange, setShowChange }} />
          </Grid>
        ) : (
          <Grid container justifyContent="space-between" mb={4}>
            {tableTitle && tableTitle}
            <Grid item alignItems="flex-end" sx={{ display: 'flex' }}>
              <ToolbarButtons
                {...{ title, disableExport }}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
}

export interface StDataGridProps
  extends ComponentProps<typeof DataGrid>,
    ToolbarProps,
    ShowChangeProps {
  noToolbar?: boolean;
  noFooter?: boolean;
}

function ColumnResize() {
  return <> </>;
}

export function StDataGrid({
  noToolbar,
  showChange = false,
  enableShowChange = false,
  title = 'Centre of Excellence',
  setShowChange = () => undefined,
  rows,
  tableTitle,
  disableExport,
  ...props
}: StDataGridProps) {

  const lang = useLanguage();
  const DataGridToolbar = React.useCallback(
    () => (
      <Toolbar
        {...{ title, showChange, setShowChange, disableExport, enableShowChange, tableTitle }}
      />
    ),
    [showChange, setShowChange, title, enableShowChange, tableTitle]
  );
  const paginationSize = props.initialState?.pagination?.paginationModel?.pageSize;
  const hasFooter = rows?.length ? (paginationSize ? rows?.length < paginationSize : rows?.length > 25) : false;
  const dataGridLocale = { EN: enUS, FR: frFR }[lang]

  return (
    <DataGrid
      slots={{
        toolbar: noToolbar ? undefined : DataGridToolbar,
        columnResizeIcon: ColumnResize,
      }}
      hideFooter={hasFooter}
      localeText={dataGridLocale.components.MuiDataGrid.defaultProps.localeText}
      slotProps={{
        filterPanel: {
          sx: {
            background: 'white',
            '& .MuiInput-input[type=text]': { padding: '8px 0 10px !important' },
            '& .MuiDataGrid-filterFormOperatorInput': { minWidth: '10rem' },
          },
        },
        baseButton: { variant: 'text' },
        pagination: {
          sx: {'.MuiTablePagination-toolbar':{ borderBottom: 'none !important'}}
        }
      }}
      autoHeight
      sx={{
        border: 'none',
        '.MuiDataGrid-withBorder': { borderRight: 'none' },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },

      }}
      rows={rows}
      {...props}
    />
  );
}
