
import {
  SeenNotificationsQuery,
  useSeenNotificationsQuery,
  useGetNotificationsQuery,
  GetNotificationsQuery,
  Content_Notifications,
} from 'generated';
import { useState } from 'react';
import { gql } from '@apollo/client';
import { useLanguage } from 'components/util/Language';
import { sortPostDate } from 'components/util/Dates';

export const _seenNotifications = gql`
  query seenNotifications {
    seenNotifications {
      notification_id
    }
  }
`;

export const _markAllAsRead = gql`
  mutation markAllAsRead($ids: [String!]) {
    markAllAsRead(ids: $ids)
  }
`;

export const _markAllAsUnread = gql`
  mutation markAllAsUnread {
    markAllAsUnread
  }
`;

export const _getNotifications = gql`
  query getNotifications($lang: EnumContent_NotificationsLanguage) {
    contentNotificationMany(filter: { language: $lang, enabled: true }) {
      _id
      enabled
      categories {
        code
        label
      }
      post_date
      key
      description
      language
      button_label
      button_link
      legacy_id
    }
  }
`;

const toId = (n: SeenNotificationsQuery['seenNotifications'][number]) => n.notification_id;

export const useNotificationsQuery = () => {
  const [notifications, setNotifications] = useState<Partial<Content_Notifications>[]>([]);
  const lang = useLanguage();

  const seenNotificationsQuery = useSeenNotificationsQuery({
    fetchPolicy: 'network-only',
  });

  const notificationsQuery = useGetNotificationsQuery({
    variables: { lang },
    onCompleted: (data: GetNotificationsQuery) => {
      if (data) {
        setNotifications([...data.contentNotificationMany].sort(sortPostDate));
      }
    },
  });

  const seenIds = seenNotificationsQuery.data
    ? seenNotificationsQuery.data?.seenNotifications?.map(toId)
    : [];
  const readNotifications = notifications.filter(
    (n) => seenIds.includes(`${n?.key}`) || seenIds.includes(`${n?.legacy_id}`)
  );
  const unreadNotifications = notifications.filter(
    (n) => !seenIds.includes(`${n?.key}`) && !seenIds.includes(`${n?.legacy_id}`)
  );
  const unreadNotificationsCount = unreadNotifications.length;

  return {
    refetchSeen: seenNotificationsQuery.refetch,
    notifications,
    readNotifications,
    unreadNotifications,
    unreadNotificationsCount,
    data: seenNotificationsQuery.data,
    loading: seenNotificationsQuery.loading || notificationsQuery.loading,
    error: seenNotificationsQuery.error || notificationsQuery.error,
  };
};
