/* eslint-disable */
import React, { useEffect } from 'react';
import { getToken, getUrlLanguage } from '../../utility';
import { useLocation } from 'react-router-dom';
import { oemShortCodeFromUrl } from '../../utility';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const globalWindow = window as any;

type InteractionValue = string | number | boolean | '';
export interface VideoAnalyticsConfig {
  videoType?: string;
  videoTitle?: string;
  appLocation?: string;
}

interface InteractionArgs {
  field: any;
  value?: InteractionValue;
}

export interface AnalyticsConfig {
  field?: string;
  value?: InteractionValue;
}

/**
 * Custom Dimension Index
 */
const DIMS = {
  UserRole: 'user_role',
  Oem: 'oem',
  Language: 'language',
  ContentGroup: 'content_group',
  LoginType: 'login_type',
};

const getContentGroup = () => globalWindow.sessionStorage.getItem('track_content_group') || '';

const resetAnalytics = () => {
  amplitude.reset();
}

const updateUserProps = () => {
  const token = getToken();

  if (!token) {
    return resetAnalytics();
  }

  if (token?.username && !amplitude.getUserId()) {
    amplitude.setUserId(token?.username);
  }

  const identifyEvent = new amplitude.Identify();

  // Set user properties
  const userRole = token.coe_role || 'guest';
  const oem = oemShortCodeFromUrl();
  const loginType = token.login_type;
  const language = getUrlLanguage(window.location.pathname);

  // Get previous user properties from session storage
  const previousUserPropsString = sessionStorage.getItem('previousUserProps');
  const previousUserProps = previousUserPropsString ? JSON.parse(previousUserPropsString) : {};

  // Check if user properties have changed
  if (
    previousUserProps.userRole !== userRole ||
    previousUserProps.oem !== oem ||
    previousUserProps.loginType !== loginType ||
    previousUserProps.language !== language
  ) {
    identifyEvent.set('user_role', userRole);
    identifyEvent.set('oem', oem);
    identifyEvent.set('login_type', loginType);
    identifyEvent.set('language', language);

    amplitude.identify(identifyEvent);

    // Update previous user properties in session storage
    const currentUserProps = {
      userRole,
      oem,
      loginType,
      language,
    };
    sessionStorage.setItem('previousUserProps', JSON.stringify(currentUserProps));
  }
};

const updateAmplitude = (args: Record<string, unknown>) => {
  if (amplitude === undefined) return;
  updateUserProps();
  if (args?.event) {
    const { event, ...rest } = args;
    amplitude.track({
      event_type: event as string,
      event_properties: {
        ...rest,
      },
    });
    return;
  }
};

//? This is the method that will be used for tracking events
const updateTracking = updateAmplitude;

const usePageViews = () => {
  const location = useLocation();
  const token = getToken();

  useEffect(() => {
    const trackingData = {
      event: 'Page View',
      page_location: `${window.location.hostname + location.pathname}`,
      page_title: `${window.document.title}`,
      user_agent: `${window.navigator.userAgent}`,
      client_id: `${token?.username}`,
    };
    updateTracking(trackingData);
  }, [location]);
};

const useInteraction = (
  { field, value }: InteractionArgs,
  isFirstLoad: React.MutableRefObject<boolean>
) => {
  useEffect(() => {
    isFirstLoad.current
      ? (isFirstLoad.current = false)
      : value !== ''
        ? updateTracking({
          event: 'Option Selected',
          category: 'User Interaction',
          label: `${field}`,
          selection: `${value}`,
        })
        : (() => { })();
  }, [value, isFirstLoad]);
};

const useClick = (field: string | JSX.Element) => {
  updateTracking({
    event: 'Element Clicked',
    category: 'User Interaction',
    label: `${field}`,
  });
};

const useDownload = (field: string | JSX.Element, url: string, fileType?: string, fileSize?: number) => {
  updateTracking({
    event: 'File Downloaded',
    category: 'User Interaction',
    label: `${field}`,
    file_url: `${url}`,
    file_type: fileType,
    file_size: fileSize,
  });
};

const useListFilter = (field: string | JSX.Element, value: string) => {
  updateTracking({
    event: 'Filter Used',
    category: 'User Interaction',
    label: `${field}`,
    filter_value: `${value}`,
  });
};

const useLinkClicked = (field: string | JSX.Element, url: string) => {
  updateTracking({
    event: 'Link Clicked',
    category: 'User Interaction',
    label: `${field}`,
    link: `${url}`,
  });
};

const useGenericEvent = (eventName: string, modalProps?: Record<string, unknown>) => {
  if (modalProps === undefined) {
    amplitude.track(eventName);
  }
  updateTracking({
    event: eventName,
    ...modalProps,
  });
};

function isPageScrollable() {
  const totalPageHeight = document.documentElement.scrollHeight;
  const viewportHeight = window.innerHeight;
  return totalPageHeight > viewportHeight;
}

const useScrollTracked = () => {
  const _window = (window as any)
  _window.__scroll = _window.__scroll ?? { depth: 0, height: 0, has_scroll: isPageScrollable() };
  const setScrollData = (newData: { depth: number; height: number; has_scroll: boolean }) => { _window.__scroll = newData }

  const updateScroll = () => {
    const scrollData = _window.__scroll
    const totalPageHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    if (!isPageScrollable) return;
    if (window.scrollY > 0 && scrollData?.depth < window.scrollY) {
      setScrollData({ depth: window.scrollY, height: totalPageHeight, has_scroll: isPageScrollable() })
    }
  }

  const trackFinalScrollPercentage = () => {
    const scrollData = _window.__scroll
    if (!scrollData || scrollData?.height === 0) return;
    const maxPercentage = Math.floor((scrollData?.depth / scrollData?.height) * 100);
    const trackingData = { event: 'Page Scroll', max_scroll_percentage: maxPercentage, has_scroll: scrollData?.has_scroll }
    updateTracking(trackingData);
    // Reset
    setScrollData({ depth: 0, height: 0, has_scroll: isPageScrollable() });
  }

  useEffect(() => {
    window.addEventListener('scrollend', updateScroll)

    // Cleanup on leaving section with scroll hook
    return () => {
      window.removeEventListener('scrollend', updateScroll);
      delete _window.__scroll
    };
  }, [])

  useEffect(() => {
    trackFinalScrollPercentage();
  }, [location.pathname])
}

const useLoadTime = (
  firstLoad: React.RefObject<boolean>,
  time: React.RefObject<number>,
  label: string
) => {
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    } else {
      updateTracking({
        event: 'Query Completed',
        category: 'API',
        label: `${label}`,
        duration: `${performance.now() - time.current}`,
      });
    }
  });
};

const googleDataLayerPush = (args: Record<string, unknown>) => globalWindow.dataLayer.push(args);

const googleInit = () => {
  if (typeof globalWindow.gtag !== 'function') {
    globalWindow.dataLayer = globalWindow.dataLayer || [];
    globalWindow.gtag = function () {
      globalWindow.dataLayer.push(arguments);
    };
    globalWindow.gtag('js', new Date());
  }
};

const amplitudeInit = () => {
  const token = getToken();
  const sessionReplay = sessionReplayPlugin({ sampleRate: 0.4 });
  if (typeof globalWindow.amplitude !== 'function') {
    if (process.env.REACT_APP_AMPLITUDE_KEY) {
      amplitude.add(sessionReplay);

      if (token) {
        amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, token.username, {
          autocapture: true,
          identityStorage: 'localStorage',
          defaultTracking: {
            sessions: true,
            pageViews: true,
            formInteractions: true,
            fileDownloads: true,
          },
        });
      } else {
        amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY, {
          autocapture: true,
          identityStorage: 'localStorage',
          defaultTracking: {
            sessions: true,
            pageViews: true,
            formInteractions: true,
            fileDownloads: true,
          },
        });
      }
    }
  }
};

export const Analytics = (() => {
  const _this = {
    init: () => {
      // googleInit();
      amplitudeInit();
      updateTracking({ event: 'Initialized' });
      return _this;
    },
    updateUserProps: updateUserProps,
    ButtonClick(
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.SyntheticEvent<Element, Event>,
      name: string | JSX.Element,
      callback: any
    ) {
      useClick(`button_click_${name}`);
      return callback && callback(event);
    },
    ContentGroup(contentGroup: string) {
      globalWindow.sessionStorage.setItem('track_content_group', contentGroup);
      amplitude.identify(new amplitude.Identify().set('content_group', contentGroup));
    },
    Download(field: string | JSX.Element, url: string, fileType?: string, fileSize?: number) {
      useDownload(field, url, fileType, fileSize);
    },
    Track: {
      Generic: useGenericEvent,
      Search: {
        Executed(keyword: string, path?: string) {
          const selection = path ? { selection: path } : {};
          updateTracking({
            event: 'Search',
            category: 'Search',
            search_phrase: keyword ? keyword : '[Search History Selected]',
            ...selection,
          });
        },
        SuggestionUsed(searchSuggestion: string, searchTerm?: string) {
          updateTracking({
            event: 'Search Suggestion Clicked',
            category: 'Search',
            search_suggestion: `${searchSuggestion}`,
            search_phrase: searchTerm ? searchTerm : '[No Results Page]',
          });
        },
      },
      Video: {
        Watched(src: string, config?: VideoAnalyticsConfig) {
          updateTracking({
            event: 'Video Watched',
            category: 'Video',
            video_src: `${src}`,
            video_title: config?.videoTitle ? config.videoTitle : '[No Type Provided]',
            video_type: config?.videoType ? config?.videoType : '[No Type Provided]',
            app_location: config?.appLocation ? config?.appLocation : '[No Location Provided]',
          });
        },
        Progress(playedPercentage: number, src: string, config?: VideoAnalyticsConfig) {
          updateTracking({
            event: 'Video Progressed',
            category: 'Video',
            video_src: `${src}`,
            video_progress: playedPercentage,
            video_title: config?.videoTitle ? config.videoTitle : '[No Type Provided]',
            video_type: config?.videoType ? config?.videoType : '[No Type Provided]',
            app_location: config?.appLocation ? config?.appLocation : '[No Location Provided]',
          });
        },
        Finished(src: string, config?: VideoAnalyticsConfig) {
          updateTracking({
            event: 'Video Finished',
            category: 'Video',
            video_src: `${src}`,
            video_title: config?.videoTitle ? config.videoTitle : '[No Type Provided]',
            video_type: config?.videoType ? config?.videoType : '[No Type Provided]',
            app_location: config?.appLocation ? config?.appLocation : '[No Location Provided]',
          });
        },
      },
    },
    Interaction(args: InteractionArgs, config?: AnalyticsConfig) {
      useInteraction(Object.assign(args, config), React.useRef(true));
    },
    LinkClick(field: string | JSX.Element, url: string) {
      useLinkClicked(field, url);
    },
    ListFilter(field: string | JSX.Element, value: string) {
      useListFilter(field, value);
    },
    LoadTime(
      label: string | undefined,
      firstLoad: React.MutableRefObject<boolean>,
      initialTime: React.MutableRefObject<number>
    ) {
      if (!label) return;
      if (label?.match(/(apiRoutesQuery)|(sterlingQuery)/g)) return;
      if (label?.match(/(Query)/g)) {
        useLoadTime(firstLoad, initialTime, `${label}`);
      }
    },
    ModalOpen(modalProps: Record<string, unknown>) {
      useGenericEvent('Modal Viewed', modalProps);
    },
    Pageview() {
      usePageViews();
    },
    Scroll() {
      useScrollTracked();
    }
  };

  return _this;
})();
