/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-bitwise */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { ContentSection } from 'suites/sterling/molecules/sections/content/ContentSection';
import { PageShell } from 'suites/sterling/molecules/shells/page/PageShell';
import { useLanguage } from 'components/util/Language';
import { BubbleCard } from 'components/charts/chart-card/BubbleCard';
import { useQuery, gql } from '@apollo/client';
import { DropdownSelector, Loading } from 'components';
import { StDataGrid } from 'suites/sterling/molecules/tables/data-grid/DataGrid';
import { CampaignSummary } from './CampaignSummary.AdherenceDashboard';
import { MediaChannelStats } from 'generated';
import { InfoTag } from 'components/chips/InfoTag';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { GRAPH_COLORS } from 'v5-theme/theme-sterling';
import { PortalPanel } from './PortalPanel';


const campaignFilterOptions = [
  {
    label: { EN: 'All Campaigns', FR: 'Toutes les campagnes' },
    value: 'all',
    dateRange: { EN: 'All Time', FR: 'Tous les temps' },
  },
  {
    label: { EN: 'Hyundai Breakaway Sales Event', FR: 'L’événement Hyundai c’est hockey' },
    value: '["Hyundai Breakaway Sales Event","L’événement Hyundai c’est hockey"]',
    dateRange: { EN: 'Oct 1 - Nov 17', FR: 'Oct 1 - Nov 17' },
  },
  {
    label: { EN: `Season to Cheer`, FR: `La vente saison festive` }, //L'événement Saison festive de Hyundai`,
    value: `["Season to Cheer","La vente saison festive","L'événement Saison festive de Hyundai"]`,
    dateRange: { EN: 'Nov 18 - Jan 3rd', FR: 'Nov 18 - Jan 3rd' },
  },
  {
    label: { EN: `I ❤ Winter Sales`, FR: `L’événement J'❤ l'hiver` },
    value: `["L’événement J'❤ l'hiver","I ❤ Winter Sales Event"]`,
    dateRange: { EN: 'Jan 3 - Feb 28', FR: 'Jan 3 - Feb 28' },
  },
  {
    label: { EN: 'Hyundai Advantage Sale Event', FR: `L'événement Avantage Hyundai` },
    value: '["Hyundai Advantage Sale Event"]',
    dateRange: { EN: 'Mar 1 - Apr 16', FR: 'Mar 1 - Apr 16' },
  },
  // {label: 'Stanley Cup Playoffs', value:'["Stanley Cup Playoffs"]', dateRange: ''},
  // {label: 'Shift into Drive', value:'["Shift into Drive"]', dateRange: ''},
  // {label: 'Winning Lineup', value:'["Winning Lineup"]', dateRange: ''},
  // {label: 'Hyundai Breakaway Sales Event', value:'["Hyundai Breakaway Sales Event"]', dateRange: ''},
  // {label: '', value:'[""]', dateRange: ''},
];

function MediaChannelStatsCard({ mediaChannel, failed, reviewsCount }: MediaChannelStats) {
  const score = (((reviewsCount - failed) / reviewsCount) * 100) | 0;
  const lang = useLanguage();
  return (
    <BubbleCard
      bubbleText={`${score}%`}
      label={mediaChannel}
      subtext={`${reviewsCount} ${{ EN: 'submissions', FR: 'soumissions' }[lang]}`}
      colorByGrade={score}
    />
  );
}

export function AdherenceDashboard() {
  const lang = useLanguage();
  const [currentCampaign, setCurrentReviewPeriod] = useState(campaignFilterOptions[0].value);
  const [previousCampaign, setPreviousCampaign] = useState('');
  const [isSidePanelOpen, setSidePanelOpen] = useState(false);
  const toggleSidePanel = () => setSidePanelOpen(!isSidePanelOpen);


  const handleReviewPeriodUpdate = (val: string) => {
    setCurrentReviewPeriod(val);
    const currentCampaignIndex = campaignFilterOptions.findIndex((a) => a.value === val);
    if (currentCampaignIndex > 1) {
      setPreviousCampaign(campaignFilterOptions[currentCampaignIndex - 1]?.value);
    } else {
      setPreviousCampaign('');
    }
  };

  const mediaChannelPassrateQuery = useQuery(
    gql`
      query getAdherenceMediaChannelsPassrates(
        $campaign: String!
        $previousCampaign: String
        $skipPrevious: Boolean!
      ) {
        adherenceMediaChannelPassrateByCampaign(
          campaign: $campaign
          previousCampaign: $previousCampaign
        ) {
          currentCampaign {
            mediaChannel
            reviewsCount
            failed
          }

          previousCampaign @skip(if: $skipPrevious) {
            mediaChannel
            reviewsCount
            failed
          }
        }
      }
    `,
    {
      variables: {
        campaign: currentCampaign,
        previousCampaign,
        skipPrevious: !previousCampaign,
      },
    }
  );

  const dealerSummaryQuery = useQuery(gql`
    query getAdherenceDealerSummary {
      adherenceReviewSummaryDashboard {
        id
        dealershipName
        zone
        topMediaChannel
        bestMediaChannel
        passed
        failed
      }
    }
  `);
  const mediaChannelStatsQueryResult: {
    currentCampaign: [MediaChannelStats];
    previousCampaign?: [MediaChannelStats];
  } = mediaChannelPassrateQuery.data?.adherenceMediaChannelPassrateByCampaign;
  const campaignHasNoReviews = !mediaChannelStatsQueryResult?.currentCampaign.length;
  const campaignSummaryProps = campaignHasNoReviews
    ? false
    : {
        /*
          ...{
              overallScore, overallScoreChange,
              totalSubmissions, totalSubmissionsChange,
              mostSubmissions, mostSubmissionsCount,
              highestScoring, highestScoringRate,
              leastSubmitted, leastSubmittedCount,
              lowestScoring, lowestScoringRate,
            }
        */
        ...(() => {
          const reduced = mediaChannelStatsQueryResult?.currentCampaign.reduce(
            (a, b) => ({ count: a.count + b.reviewsCount, fail: a.fail + b.failed }),
            { count: 0, fail: 0 }
          );
          const previousCampaignReduced =
            mediaChannelStatsQueryResult?.previousCampaign?.reduce(
              (a, b) => ({ count: a.count + b.reviewsCount, fail: a.fail + b.failed }),
              { count: 0, fail: 0 }
            );
          const mostSubmitted = [...mediaChannelStatsQueryResult.currentCampaign].sort(
            (a, b) => b.reviewsCount - a.reviewsCount
          )[0];
          const highestScored = [...mediaChannelStatsQueryResult.currentCampaign].sort(
            (a, b) => b.reviewsCount - b.failed - (a.reviewsCount - a.failed)
          )[0];
          const leastSubmitted = [...mediaChannelStatsQueryResult.currentCampaign].sort(
            (a, b) => a.reviewsCount - b.reviewsCount
          )[0];
          const lowestScoring = [...mediaChannelStatsQueryResult.currentCampaign].sort(
            (a, b) => b.failed / b.reviewsCount - a.failed / a.reviewsCount
          )[0];

          const overallScore = (1000 - Math.round((reduced.fail / reduced.count) * 1000)) / 10;
          const previousOverallScore = previousCampaignReduced
            ? (1000 -
                Math.round(
                  (previousCampaignReduced.fail / previousCampaignReduced.count) * 1000
                )) /
              10
            : 0;

          return {
            overallScore: (1000 - Math.round((reduced.fail / reduced.count) * 1000)) / 10,
            overallScoreChange: overallScore - (previousOverallScore || overallScore),
            totalSubmissions: reduced.count,
            totalSubmissionsChange:
              reduced.count - (previousCampaignReduced?.count ?? reduced.count), // Make the change 0 if no value for previousCampaignReduced
            mostSubmissions: mostSubmitted.mediaChannel,
            mostSubmissionsCount: mostSubmitted.reviewsCount,
            highestScoring: highestScored.mediaChannel,
            highestScoringRate:
              (1000 - Math.round((highestScored.failed / highestScored.reviewsCount) * 1000)) /
              10,
            leastSubmitted: leastSubmitted.mediaChannel,
            leastSubmittedCount: leastSubmitted.reviewsCount,
            lowestScoring: lowestScoring.mediaChannel,
            lowestScoringRate:
              (1000 - Math.round((lowestScoring.failed / lowestScoring.reviewsCount) * 1000)) /
              10,
          };
        })(),
      };

  const pieData =
    mediaChannelPassrateQuery.data?.adherenceMediaChannelPassrateByCampaign?.currentCampaign?.map(
      (data: unknown, idx: number) => ({
        ...(data || []),
        color: Object.values(GRAPH_COLORS)[idx],
      })
    ) || [];

  if (dealerSummaryQuery.loading && !dealerSummaryQuery.data?.adherenceReviewSummaryDashboard)
    return <Loading />;

  return (
    <PageShell>
      <Button onClick={toggleSidePanel}>Panel</Button>
      <PortalPanel  />
      <ContentSection
        noBottomPadding
        maxWidth="lg"
        title={
          <Stack
            direction="column"
            alignSelf="flex-start"
            alignItems="flex-start"
            component={Box}
          >
            <Typography variant="h3">
              {
                {
                  EN: 'Creative submissions breakdown.',
                  FR: 'Ventilation des soumissions créatives.',
                }[lang]
              }
            </Typography>
          </Stack>
        }
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md="auto" component={Box} sx={{ textAlign: 'left' }}>
            <DropdownSelector
              style={{ maxWidth: '400px', minWidth: '250px' }}
              formHelperText={{ EN: 'Campaign Period', FR: 'Période de la campagne' }[lang]}
              initialValue={currentCampaign}
              setFunction={handleReviewPeriodUpdate}
              dropdownOptions={campaignFilterOptions.map((a) => ({
                ...a,
                label: a.label[lang],
              }))}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md="auto"
            display="flex"
            alignItems="center"
            sx={{ mt: { xs: 4, md: 0 } }}
          >
            <Typography variant="caption" mr={2}>
              {{ EN: 'Campaign time period: ', FR: 'Plage de dates de la campagne: ' }[lang]}
            </Typography>
            <Typography variant="caption" fontWeight={700}>
              {/* Gets date range via finding index of currentReviewPeriod */}
              {
                campaignFilterOptions[
                  campaignFilterOptions.map((a) => a.value).indexOf(currentCampaign)
                ].dateRange[lang]
              }
            </Typography>
          </Grid>
        </Grid>
      </ContentSection>
      {!campaignHasNoReviews && (
        <>
          <ContentSection maxWidth="lg" noBottomPadding>
            <Grid container>
              <Grid item xs={12} textAlign="left" mb={4}>
                <Typography variant="h4">
                  {{ EN: 'Summary of Submissions', FR: 'Résumé des soumissions' }[lang]}
                </Typography>
                <Typography variant="subtitle2">
                  {
                    {
                      EN: 'How the Hyundai dealer network is adhering to brand guidelines.',
                      FR: 'Comment le réseau de concessionnaires adhère aux directives de la marque Hyundai.',
                    }[lang]
                  }
                </Typography>
                <InfoTag
                  linkText={{
                    EN: 'How is this information calculated?',
                    FR: 'Comment ces informations sont-elles calculées?',
                  }[lang]}
                  tooltipContent={{
                    EN: 'The information listed below showcases aggregates related to the entire dealer network.',
                    FR: `Les informations ci-dessous présentent des agrégats relatifs à l'ensemble du réseau de concessionnaires.`,
                  }[lang]}
                />
              </Grid>
              <Grid item>
                {!!campaignSummaryProps && <CampaignSummary {...campaignSummaryProps} />}
              </Grid>
            </Grid>
          </ContentSection>
          <ContentSection noBottomPadding maxWidth="lg">
            <Grid container spacing={8}>
              <Grid container item lg={6}>
                <Grid item xs={12} textAlign="left" mb={4}>
                  <Typography variant="h4">
                    {
                      {
                        EN: 'Distribution of media.',
                        FR: '[FR]Distribution of media.',
                      }[lang]
                    }
                  </Typography>
                  <Typography variant="subtitle2">
                    {
                      {
                        EN: 'Understand what media in your network is being used the most amongst all your dealers.',
                        FR: '[FR]Understand what media in your network is being used the most amongst all your dealers.',
                      }[lang]
                    }
                  </Typography>
                  <InfoTag
                    linkText={{
                      EN: 'What can I learn from this?',
                      FR: '[FR]What can I learn from this?',
                    }[lang]}
                    tooltipContent={{
                      EN: 'The percentages below shows the number of submissions for the related media channel.',
                      FR: `[FR] The percentages below shows the number of submissions for the related media channel.`,
                    }[lang]}
                  />
                </Grid>
                <Grid container item>
                  {!mediaChannelPassrateQuery.loading &&
                    mediaChannelPassrateQuery.data?.adherenceMediaChannelPassrateByCampaign
                      ?.currentCampaign && (
                      <Stack component={Grid} width="100%">
                        <ResponsiveContainer aspect={0.5} maxHeight={600}>
                          <PieChart>
                            <Pie
                              dataKey="reviewsCount"
                              nameKey="mediaChannel"
                              innerRadius={0}
                              outerRadius="90%"
                              startAngle={0}
                              data={
                                mediaChannelPassrateQuery.data
                                  ?.adherenceMediaChannelPassrateByCampaign?.currentCampaign
                              }
                            >
                              {pieData.map((data: { color: string }, idx: number) => (
                                <Cell key={`cell-${idx}`} fill={data.color} />
                              ))}
                            </Pie>
                            <Legend
                              margin={{ top: 100 }}
                              formatter={(value) => (
                                <span style={{ color: 'black' }}>{value}</span>
                              )}
                            />
                          </PieChart>
                        </ResponsiveContainer>
                      </Stack>
                    )}
                </Grid>
              </Grid>
              <Grid container item lg={6}>
                <Grid container item>
                  <Grid item xs={12} textAlign="left" mb={4}>
                    <Typography variant="h4">
                      {
                        {
                          EN: 'Media Channel Breakdown',
                          FR: 'Ventilation des canaux médiatiques',
                        }[lang]
                      }
                    </Typography>
                    <Typography variant="subtitle2">
                      {
                        {
                          EN: 'View how well dealers across your network are adhering to the Hyundai brand.',
                          FR: 'Voyez dans quelle mesure les concessionnaires de votre réseau adhèrent à la marque Hyundai.',
                        }[lang]
                      }
                    </Typography>
                    <InfoTag
                      linkText={{
                        EN: 'What do the percentages mean?',
                        FR: 'Que signifient les pourcentages?',
                      }[lang]}
                      tooltipContent={{
                        EN: 'The percentages below shows the number of passes for the related media channel.',
                        FR: `Les pourcentages ci-dessous indiquent le nombre de passages pour le canal médiatique concerné.`,
                      }[lang]}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  {!mediaChannelPassrateQuery.loading &&
                    mediaChannelPassrateQuery.data?.adherenceMediaChannelPassrateByCampaign?.currentCampaign.map(
                      (mediaChannelRating: MediaChannelStats) => (
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          key={mediaChannelRating.mediaChannel}
                          sx={{
                            hr: { display: { xs: 'none', sm: 'block' } },
                            '&:nth-of-type(4n) hr': { display: { sm: 'block' } },
                            '&:nth-of-type(3n) hr': { display: { md: 'none' } },
                            borderBottom: { md: '1px solid lightgrey', xs: 'none' },
                            py: 4,
                          }}
                        >
                          <MediaChannelStatsCard {...mediaChannelRating} />
                          <Divider
                            sx={{
                              height: '75%',
                              transform: 'translateY(-115%)',
                              borderColor: 'lightgrey',
                            }}
                            orientation="vertical"
                          />
                        </Grid>
                      )
                    )}
                </Grid>
              </Grid>
            </Grid>
          </ContentSection>
        </>
      )}
      {dealerSummaryQuery.data?.adherenceReviewSummaryDashboard && (
        <ContentSection
          maxWidth="lg"
          sx={{
            overflow: 'auto !important',
            maxWidth: { xs: '99vw', md: '65vw', lg: 'unset' },
          }}
        >
          <StDataGrid
            loading={dealerSummaryQuery.loading}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 20, page: 0 },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            pageSizeOptions={[20, 50, 100]}
            tableTitle={
              <Grid container item lg={7}>
                <Grid item xs={12} textAlign="left" >
                  <Typography
                    variant="h4"
                    textAlign="left"
                    sx={{ paddingLeft: 0, marginBottom: 4 }}
                  >
                    {
                      {
                        EN: 'Dealer Submission breakdown',
                        FR: 'Ventilation des soumissions des concessionnaires',
                      }[lang]
                    }
                  </Typography>
                  <Typography variant="subtitle2">
                    {
                      {
                        EN: 'View how well dealers across your network are adhering to the Hyundai brand.',
                        FR: 'Voyez dans quelle mesure les concessionnaires de votre réseau adhèrent à la marque Hyundai.',
                      }[lang]
                    }
                  </Typography>
                  <InfoTag
                    linkText={{
                      EN: 'How is this information collected?',
                      FR: 'Comment ces informations sont-elles collectées?',
                    }[lang]}
                    tooltipContent={{
                      EN: 'The information below shows a breakdown of the media submitted by each dealer for the campaign selected at the top of the page.',
                      FR: `Les informations ci-dessous présentent une ventilation des supports soumis par chaque concessionnaire pour la campagne sélectionnée en haut de la page.`,
                    }[lang]}
                  />
                </Grid>
              </Grid>
            }
            sx={{
              '.MuiDataGrid-columnHeaderTitle': { fontWeight: 600, fontSize: 18 },
              '.MuiDataGrid-withBorder': { borderRight: 'none' },
              '.odd': { background: (theme) => theme.palette.background.paper },
              border: 'none',
            }}
            columns={[
              {
                field: 'dealershipName',
                headerName: { EN: 'Dealership Name', FR: 'Nom du concessionnaire' }[lang],
                flex: 0.9,
                minWidth: 300,
              },
              {
                field: 'zone',
                headerName: { EN: 'Zone', FR: 'Zone' }[lang],
                flex: 0.2,
                align: 'center',
                headerAlign: 'center',
              },
              {
                field: 'failed',
                valueFormatter: (param) => `${param}`,
                headerName: { EN: '# of fails', FR: `Nombre d'échecs` }[lang],
                flex: 0.3,
                align: 'center',
                headerAlign: 'center',
              },
              {
                field: 'passed',
                valueFormatter: (param) => `${param}`,
                headerName: { EN: '# of passes', FR: `Nombre de réussites` }[lang],
                flex: 0.3,
                align: 'center',
                headerAlign: 'center',
              },
              {
                field: 'bestMediaChannel',
                headerName: { EN: 'Top Channel', FR: 'Meilleur canal' }[lang],
                flex: 0.55,
                minWidth: 200,
              },
              {
                field: 'topMediaChannel',
                headerName: {
                  EN: 'Most Submitted Channel',
                  FR: 'Canal le plus soumis',
                }[lang],
                flex: 0.55,
                minWidth: 200,
              },
            ]}
            rows={dealerSummaryQuery.data?.adherenceReviewSummaryDashboard.map(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ({ __typename, ...rest }: { __typename: string }) => rest
            )}
          />
        </ContentSection>
      )}
    </PageShell>
  );
}
