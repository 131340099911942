import { breakpointsInt } from 'assets/styles/themes/resources';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLanguage } from 'components/util/Language';
import styled from 'styled-components';
import { handleEnterKey } from 'utility';
import { Content_Notifications } from 'generated';
import { OemTheme } from 'assets/styles/themes/functions';
import { ReactComponent as Bell } from '../../../../assets/icons/sterling-bell.svg';
import { NotificationPane } from './NotificationPane';
import { Analytics } from 'components/util/AnalyticEvents';

const BELL_SIZE_PX = 50;

const BellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-end;

  margin-right: ${BELL_SIZE_PX / 6}px !important;
  overflow-x: visible;

  svg {
    width: ${BELL_SIZE_PX}px;
    height: ${BELL_SIZE_PX}px;

    circle {
      fill: ${OemTheme.figma.colour.base.dark};
    }

    path {
      fill: ${OemTheme.figma.colour.contrast.dark};
    }
  }

  :hover {
    cursor: pointer;
  }
`;

const UnreadCountCircle = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 !important;
  padding: 0 !important;
  bottom: ${BELL_SIZE_PX + BELL_SIZE_PX / 6}px;
  right: ${-BELL_SIZE_PX / 6}px;
  width: ${BELL_SIZE_PX / 2}px;
  height: ${BELL_SIZE_PX / 2}px;
  border-radius: 50%;
  background-color: ${OemTheme.figma.colour.base.accent};

  /* Text */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: ${BELL_SIZE_PX / 4}px;
  color: ${OemTheme.figma.colour.contrast.accent};
`;

export function NotificationBell(props: {
  notifications: Partial<Content_Notifications>[];
  count: number;
  handleClick: (id: string) => void;
}) {
  const { notifications, count, handleClick } = props;
  const history = useHistory();
  const lang = useLanguage();

  const [paneOpen, setPaneOpen] = useState(false);

  const hasNotifications = count > 0;
  const isMobile = window.innerWidth <= breakpointsInt.md;

  const bellClick = () => {
    Analytics.Track.Generic('Notification Bell Clicked');
    if (hasNotifications && !isMobile) {
      setPaneOpen(true);
    } else {
      history.push({EN:`/notifications`, FR: '/fr/notifications'}[lang]);
    }
  };

  const closePane = () => setPaneOpen(false);

  return (
    <BellWrapper id='notification-bell'>
      <Bell
        onClick={bellClick}
        onKeyPress={(e) => handleEnterKey(e, () => bellClick())}
        aria-label="Link to notifications page"
        role="button"
        tabIndex={0}
      />
      {count > 0 && <UnreadCountCircle>{count > 9 ? '' : count}</UnreadCountCircle>}
      {paneOpen && (
        <NotificationPane
          notifications={notifications}
          handleClick={handleClick}
          closePane={closePane}
        />
      )}
    </BellWrapper>
  );
}
