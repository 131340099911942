import React from 'react';
import { Box, Divider, Grid, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useLanguage } from 'components/util/Language';

function ChangeCaption({
  value,
  label,
  passCondition,
}: {
  value: string | number;
  label?: string;
  passCondition?: boolean;
}) {
  const lang = useLanguage();
  return (
    <Typography variant="caption" sx={{ fontWeight: 700, color: (theme) => theme.palette.grey[700] }}>
      <Typography
        component="span"
        sx={{
          color: (theme) =>
            passCondition === undefined
              ? theme.palette.grey[700]
              : passCondition
              ? 'green'
              : 'red',
        }}
        variant="caption"
        fontWeight={700}
      >
        {value}
      </Typography>
      {` ${
        label ||
        (passCondition
          ? { EN: 'increase', FR: ' [FR]increase' }[lang]
          : { EN: 'decrease', FR: ' [FR]decrease' }[lang])
      }`}
    </Typography>
  );
}

function VerticalDivider(props: { sx?: SxProps<Theme> }) {
  return (
    <Divider
      sx={{
        height: '75%',
        borderColor: 'lightgrey',
        display: { xs: 'none', md: 'flex' },
        alignSelf: 'center',
        position: 'relative',
        mb: (theme) => theme.spacing(4),
        ...props.sx,
      }}
      orientation="vertical"
    />
  );
}

export function CampaignSummary({
  overallScore,
  overallScoreChange,
  totalSubmissions,
  totalSubmissionsChange,
  mostSubmissions,
  mostSubmissionsCount,
  highestScoring,
  highestScoringRate,
  leastSubmitted,
  leastSubmittedCount,
  lowestScoring,
  lowestScoringRate,
}: {
  overallScore: number;
  overallScoreChange: number;
  totalSubmissions: number;
  totalSubmissionsChange: number;
  mostSubmissions: string;
  mostSubmissionsCount: number;
  highestScoring: string;
  highestScoringRate: number;
  leastSubmitted: string;
  leastSubmittedCount: number;
  lowestScoring: string;
  lowestScoringRate: number;
}) {
  const lang = useLanguage();
  return (
    <Box component={Grid} container>
      <Stack
        direction="row"
        component={Grid}
        container
        sx={{
          '.MuiTypography-root': { textAlign: 'left !important' },
          '.MuiGrid-item': {
            px: { xs: 4, md: 8 },
            pb: { xs: 4, md: 0 },
            alignSelf: 'center',
            mb: { xs: 8, md: 4 },
          },
        }}
      >
        <Stack direction="column" component={Grid} item>
          <Typography variant="caption">
            {{ EN: 'Overall', FR: 'Globalement' }[lang]}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: () => (overallScore >= 30 ? 'green' : 'red') }}
            fontWeight={700}
          >
            {overallScore}%
          </Typography>
          {overallScoreChange !== 0 && (
            <ChangeCaption
              value={`${overallScoreChange > 0 ? '+' : ''}${overallScoreChange}%`.replace(
                '-',
                '- '
              )}
              passCondition={overallScoreChange > 0}
            />
          )}
          <Typography variant="caption">&nbsp;</Typography>
        </Stack>
        <VerticalDivider />
        <Stack direction="column" component={Grid} item>
          <Typography variant="caption">
            {{ EN: 'Total Submissions', FR: 'Total des soumissions' }[lang]}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700}>
            {totalSubmissions}
          </Typography>
          {totalSubmissionsChange !== 0 && (
            <ChangeCaption
              label={{ EN: 'submissions', FR: 'soumissions' }[lang]}
              value={`${
                totalSubmissionsChange > 0 ? '+' : ''
              } ${totalSubmissionsChange}`.replace('-', '- ')}
              passCondition={totalSubmissionsChange >= 30}
            />
          )}
          <Typography variant="caption">&nbsp;</Typography>
        </Stack>
        <VerticalDivider />
        <Stack direction="column" component={Grid} item>
          <Typography variant="caption">
            {{ EN: 'Most Submitted', FR: 'Média le plus soumis' }[lang]}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700}>
            {mostSubmissions}
          </Typography>
          <ChangeCaption
            label={{ EN: 'submissions', FR: 'soumissions' }[lang]}
            value={mostSubmissionsCount}
          />
        </Stack>
        <VerticalDivider />
        <Stack direction="column" component={Grid} item>
          <Typography variant="caption">
            {{ EN: 'Highest Scoring Channel', FR: 'Chaîne de médias ayant obtenu le meilleur score' }[lang]}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700}>
            {highestScoring}
          </Typography>
          <ChangeCaption
            label={{ EN: 'pass rate', FR: 'taux de réussite' }[lang]}
            value={`${highestScoringRate}%`}
            passCondition={highestScoringRate >= 30}
          />
        </Stack>
        <VerticalDivider />
        <Stack direction="column" component={Grid} item>
          <Typography variant="caption">
            {{ EN: 'Least Submitted', FR: 'Média le moins soumis' }[lang]}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700}>
            {leastSubmitted}
          </Typography>
          <ChangeCaption
            label={{ EN: 'submissions', FR: 'soumissions' }[lang]}
            value={`${leastSubmittedCount}`}
          />
        </Stack>
        <VerticalDivider />
        <Stack direction="column" component={Grid} item>
          <Typography variant="caption">
            {{ EN: 'Lowest Scoring Channel', FR: 'Chaîne de médias la moins bien notée' }[lang]}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700}>
            {lowestScoring}
          </Typography>
          <ChangeCaption
            label={{ EN: 'pass rate', FR: 'taux de réussite' }[lang]}
            value={`${lowestScoringRate}%`}
            passCondition={lowestScoringRate >= 30}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
