import React, { Suspense, ReactNode } from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { OemTheme } from 'assets/styles/themes/functions';
import { PrivateRoute } from './PrivateRoute';
import usePageTitle from 'suites/sterling/app/hooks/usePageTitle';
import { useLanguage } from 'components/util/Language';

const AnalyticsDashboard = React.lazy(
  () => import('views/pages/analytics/analytics-dashboard/AnalyticsDashboard')
);

const SearchResults = React.lazy(() => import('views/pages/search/Search.Results'));
const SearchAdmin = React.lazy(() => import('views/pages/admin/search/SEOGeneration'));

const EducationCentre = React.lazy(
  () => import('views/pages/education-centre/EducationCentre')
);

const ProgramUpdates = React.lazy(() => import('views/pages/program-updates/ProgramUpdates'));
const PostDetails = React.lazy(() => import('views/pages/post-details/PostDetails'));
const Notifications = React.lazy(() => import('views/pages/notifications/Notifications'));

const ExecutiveSummary = React.lazy(
  () => import('views/pages/admin/executive-summary/ExecutiveSummary')
);

const ExecutiveDashboard = React.lazy(
  () => import('suites/portal/pages/executive/ExecutiveDashboard')
);

const PreferredVendors = React.lazy(
  () => import('views/pages/preferred-vendors/PreferredVendors')
);
const LighthouseQuestions = React.lazy(
  () => import('views/pages/admin/lighthouse-questions/LighthouseQuestions')
);
const AnalyticsManagement = React.lazy(
  () => import('views/pages/analytics/analytics-management/AnalyticsManagement')
);
const Rewards = React.lazy(() => import('views/pages/admin/rewards/Rewards'));
const AnalyticsPlayground = React.lazy(
  () => import('views/pages/admin/analytics/AnalyticsPlayground')
);
const Dashboard = React.lazy(
  () => import('views/pages/my-performance/performance-dashboard/PerformanceDashboard')
);
const PerformanceReport = React.lazy(
  () => import('views/pages/my-performance/performance-report/PerformanceReport')
);
const CategoryDashboard = React.lazy(
  () => import('views/pages/management-report/category-dashboard/CategoryDashboard')
);
const SummaryReport = React.lazy(
  () => import('views/pages/management-report/summary-report/SummaryReport')
);

// const Home = React.lazy(() => import('views/pages/home/Home'));

const CreateNewAccount = React.lazy(() => import('views/pages/admin/account-management'));

const Resources = React.lazy(() => import('../views/pages/coe-standards/Resources'));

const ManagementDashboard = React.lazy(
  () => import('views/pages/management-report/management-dashboard/ManagementDashboard')
);

const PackagedVendorDashboard = React.lazy(
  () => import('suites/portal/pages/vendor-dashboard/PackagedVendorDashboard')
);

const ProgressReport = React.lazy(() => import('views/pages/progress-report/ProgressReport'));
const ProgressHome = React.lazy(() => import('views/pages/progress-report/ProgressHome'));

const withSuspense = (node: ReactNode) => {
  return <Suspense fallback={<></>}>{node}</Suspense>;
};

export interface ClientRoute {
  title: string;
  titleFr: string;
  itemtype?: string;
  path: string;
  pathFr: string;
  order: number;
  content?: string;
  component: () => JSX.Element;
  private?: boolean;
  featureName?: string;
  noContent?: boolean;
  background?: string;
  hideNavLink?: boolean;
  children: ClientRoute[];
}

const myPerformanceChildren: ClientRoute[] = [
  {
    path: '/my-performance/dashboard',
    pathFr: '/fr/my-performance/dashboard',
    title: 'Dashboard',
    titleFr: 'Planche de bord',
    order: 0,
    component: () => withSuspense(<Dashboard />),
    private: true,
    children: [],
  },
  {
    path: '/my-performance/performance-report',
    pathFr: '/fr/my-performance/performance-report',
    title: 'Performance Report',
    titleFr: 'Rapport de performance',
    order: 1,
    component: () => withSuspense(<PerformanceReport />),
    private: true,
    children: [],
  },
  {
    path: '/my-performance/dashboard/:unitCode/:assessmentCode?',
    pathFr: '/fr/my-performance/dashboard/:unitCode/:assessmentCode?',
    title: 'Dashboard',
    titleFr: 'Planche de bord',
    order: 0,
    component: () => withSuspense(<Dashboard />),
    hideNavLink: true,
    private: true,
    children: [],
  },
  {
    path: '/my-performance/performance-report/:unitCode/:assessmentCode?/:categoryCode?',
    pathFr: '/fr/my-performance/performance-report/:unitCode/:assessmentCode?/:categoryCode?',
    title: 'Performance Report',
    titleFr: 'Rapport de performance',
    order: 0,
    component: () => (
      <Box style={{ marginTop: '110px' }}>
        <Container maxWidth="xl">{withSuspense(<PerformanceReport />)}</Container>
      </Box>
    ),
    hideNavLink: true,
    private: true,
    children: [],
  },
];

const managementReportChildren: ClientRoute[] = [
  {
    path: '/management-report/module-dashboard',
    pathFr: '/fr/management-report/module-dashboard',
    title: 'Module Dashboard',
    titleFr: 'Tableau de bord des module',
    order: 1,
    component: () => withSuspense(<CategoryDashboard />),

    private: true,
    children: [],
  },
  {
    path: '/management-report/management-dashboard',
    pathFr: '/fr/management-report/management-dashboard',
    title: 'Management Dashboard',
    titleFr: 'Tableau de bord de gestion',
    order: 0,
    component: () => withSuspense(<ManagementDashboard />),
    private: true,
    children: [],
  },
  {
    path: '/management-report/summary-report',
    pathFr: '/fr/management-report/summary-report',
    title: 'Summary Report',
    titleFr: 'Rapport sommaire',
    order: 3,
    component: () => withSuspense(<SummaryReport />),
    private: true,
    children: [],
  },
  {
    path: '/management-report/vendor-dashboard',
    pathFr: '/fr/management-report/vendor-dashboard',
    title: 'Vendor Dashboard',
    titleFr: 'Tableau de bord du fournisseur',
    order: 2,
    component: () => (
      <Box style={{ marginTop: '110px' }}>
        <Container maxWidth="xl">{withSuspense(<PackagedVendorDashboard />)}</Container>
      </Box>
    ),
    private: true,
    children: [],
  },
];

const superAdminChildren: ClientRoute[] = [
  {
    path: '/admin/create-account',
    pathFr: '/fr/admin/create-account',
    itemtype: 'standards',
    title: 'Account Management',
    titleFr: '[Fr] Account Management',
    order: 0,
    component: () => withSuspense(<CreateNewAccount />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: '/admin/analytics',
    pathFr: '/fr/admin/analtyics',
    itemtype: 'standards',
    title: 'Analytics Playground',
    titleFr: '[Fr] Analytics Playground',
    order: 0,
    component: () => withSuspense(<AnalyticsPlayground />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: '/admin/search',
    pathFr: '/fr/admin/search',
    itemtype: 'search',
    title: 'Search Playground',
    titleFr: '[Fr] Search Playground',
    order: 0,
    component: () => withSuspense(<SearchAdmin />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: '/admin/executive-summary',
    pathFr: '/fr/admin/executive-summary',
    itemtype: 'standards',
    title: 'Executive Summary',
    titleFr: '[Fr] Executive Summary',
    order: 1,
    component: () => withSuspense(<ExecutiveSummary />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: '/admin/executive-dashboard',
    pathFr: '/fr/admin/executive-dashboard',
    itemtype: 'standards',
    title: 'Executive Dashboard',
    // [FR] NEEDED
    titleFr: 'Executive Dashboard',
    order: 2,
    component: () => withSuspense(<ExecutiveDashboard />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: '/admin/lighthouse-questions',
    pathFr: '/fr/admin/lighthouse-questions',
    itemtype: 'standards',
    title: 'Lighthouse v. Questions',
    titleFr: '[Fr] Lighthouse v. Questions',
    order: 3,
    component: () => withSuspense(<LighthouseQuestions />),
    private: true,
    noContent: false,
    children: [],
  },
];

const analyticsChildren: ClientRoute[] = [
  {
    path: '/analytics/dashboard',
    pathFr: '/analytics/dashboard',
    itemtype: 'standards',
    title: 'Dashboard',
    titleFr: 'Planche de bord',
    order: 0,
    component: () => withSuspense(<AnalyticsDashboard />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: '/analytics/management',
    pathFr: '/analytics/management',
    itemtype: 'standards',
    title: 'Management',
    // [FR] NEEDED
    titleFr: 'Management',
    order: 1,
    component: () => withSuspense(<AnalyticsManagement />),
    private: true,
    noContent: false,
    children: [],
  },
];

export const clientRoutes: ClientRoute[] = [
  {
    path: '/',
    pathFr: '/fr/',
    itemtype: 'home',
    title: 'Home',
    titleFr: 'Page d’accueil',
    order: 0,
    component: () => withSuspense(<ProgressHome />),
    private: false,
    noContent: false,
    children: [],
  },
  {
    path: '/program-updates',
    pathFr: '/fr/program-updates',
    itemtype: 'latest-updates',
    title: 'Program Updates',
    titleFr: 'Mises à jour sur le programme',
    order: 0,
    background: OemTheme.colour.background.primary,
    component: () => withSuspense(<ProgramUpdates />),
    private: false,
    noContent: false,
    children: [],
  },
  {
    path: '/admin',
    pathFr: '/fr/admin',
    itemtype: 'management',
    title: 'Administrative',
    titleFr: '[Fr] Administrative',
    order: 0,
    component: () => <span />,
    private: true,
    noContent: true,
    children: superAdminChildren.sort((chA, chB) => chA.order - chB.order),
  },
  {
    path: '/management-report',
    pathFr: '/fr/management-report-fr',
    itemtype: 'management',
    title: 'Management Report',
    titleFr: 'Rapport de direction',
    order: 1,
    component: () => <span />,
    private: true,
    noContent: true,
    children: managementReportChildren.sort((chA, chB) => chA.order - chB.order),
  },
  {
    path: '/my-performance',
    pathFr: '/fr/french-my-performance',
    itemtype: 'performance',
    title: 'My Performance',
    titleFr: 'Ma performance',
    order: 2,
    component: () => <span />,
    private: true,
    noContent: true,
    children: myPerformanceChildren.sort((chA, chB) => chA.order - chB.order),
  },
  {
    path: '/analytics',
    pathFr: '/fr/analytics',
    itemtype: 'analytics',
    title: 'Analytics',
    titleFr: 'Analytics',
    order: 2,
    component: () => <span />,
    private: true,
    noContent: true,
    children: analyticsChildren.sort((chA, chB) => chA.order - chB.order),
  },
  {
    path: '/education',
    pathFr: '/fr/education',
    itemtype: 'education',
    title: 'Education & Training',
    titleFr: 'Éducation et formation',
    order: 7,
    component: () => withSuspense(<EducationCentre />),
    private: false,
    noContent: false,
    children: [],
  },

  {
    path: '/notifications',
    pathFr: '/fr/notifications',
    itemtype: 'latest-updates',
    title: 'Notifications',
    titleFr: 'Notifications',
    order: 1,
    component: () => withSuspense(<Notifications />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: '/search',
    pathFr: '/fr/search',
    itemtype: 'search',
    title: 'Search Results',
    titleFr: 'Résultats de recherche',
    order: 0,
    component: () => withSuspense(<SearchResults />),
    private: true,
    noContent: false,
    hideNavLink: true,
    children: [],
  },
  {
    path: '/preferred-vendors',
    pathFr: '/fr/preferred-vendors',
    itemtype: 'preferred-vendors',
    title: 'Preferred Vendors',
    titleFr: 'Fournisseurs privilégiés',
    order: 1,
    component: () => withSuspense(<PreferredVendors />),
    private: true,
    noContent: false,
    children: [],
  },
  {
    path: '/post-details/:postId',
    pathFr: '/fr/post-details/:postId',
    itemtype: 'latest-updates',
    title: 'Post Details',
    titleFr: 'Post Details',
    order: 1,
    component: () => withSuspense(<PostDetails />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
  {
    path: '/webinar/:postId',
    pathFr: '/fr/webinar/:postId',
    itemtype: 'latest-updates',
    title: 'Education Post Details',
    titleFr: 'Webinar',
    order: 1,
    component: () => withSuspense(<PostDetails />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
  {
    path: '/program-update/:postId',
    pathFr: '/fr/program-update/:postId',
    itemtype: 'latest-updates',
    title: 'Program Update',
    titleFr: 'Mises à jour sur le Programme',
    order: 1,
    component: () => withSuspense(<PostDetails />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
  {
    path: '/business-insight/:postId',
    pathFr: '/fr/business-insight/:postId',
    itemtype: 'business-insights',
    title: 'Business Insights',
    titleFr: 'Perspectives Stratégiques',
    order: 1,
    component: () => withSuspense(<PostDetails />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
  {
    path: '/rewards',
    pathFr: '/fr/rewards',
    itemtype: 'rewards',
    title: 'Rewards',
    titleFr: 'Récompenses',
    order: 5,
    component: () => withSuspense(<Rewards />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    children: [],
  },
  {
    path: '/progress-report',
    pathFr: '/fr/progress-report',
    itemtype: 'progress-report',
    title: 'Progress Report',
    titleFr: 'Progress Report',
    order: 5,
    component: () => withSuspense(<ProgressReport />),
    private: false,
    noContent: false,
    background: OemTheme.colour.background.primary,
    hideNavLink: true,
    children: [],
  },
];

function HookWrappedComponent(route: ClientRoute) {
  const lang = useLanguage();
  const RouteComponent = route.component;
  usePageTitle({ EN: route.title, FR: route.titleFr }[lang]);
  return <RouteComponent />;
}

const ClientRoutes = (props: { routes: ClientRoute[] }) => {
  const { routes } = props;
  const mapRoute = (route: ClientRoute): JSX.Element[] => {
    const thisRoute = route.private ? (
      <PrivateRoute
        exact
        path={[route.path, route.pathFr]}
        component={() => HookWrappedComponent(route)}
      />
    ) : (
      <Route
        exact
        path={[route.path, route.pathFr]}
        component={() => HookWrappedComponent(route)}
      />
    );
    return [thisRoute, ...ClientRoutes({ routes: route.children }).flat()];
  };

  return [routes.map(mapRoute)].flat();
};

const ResourceRoutes = (props: { routes: string[] }) =>
  props.routes.map((route) => (
    <Route path={route} render={() => withSuspense(<Resources />)} key={route} />
  ));

export function Routes() {
  const location = useLocation();

  const isDownloadable = /(pdf|zip)$/.test(location.pathname);

  return (
    <Switch>
      {ClientRoutes({ routes: clientRoutes })}
      {ResourceRoutes({
        routes: [
          '/:fr?/standards/:relativePath*',
          '/:fr?/program-standards-2021/:relativePath*',
          '/:fr?/support/:relativePath*',
          '/:fr?/changelog/:relativePath*',
          '/:fr?/recognition/:relativePath*',
          '/:fr?/preferred-vendors/:relativePath*',
          '/:fr?/resources/:relativePath*',
          '/:fr?/education-materials/:relativePath*',
          '/:fr?/latest-updates/:relativePath*',
        ],
      })}
      {!isDownloadable && (
        <>
          {/* Catch for extra slashes in url  */}
          <Redirect path="*//+" to={location.pathname.replace(/(.+\/)\/\/+$/, '$1')} />
          <Route path="*" render={() => <Redirect to="../" />} />
        </>
      )}
    </Switch>
  );
}
